import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Heading component will render styled heading element with corresponding element when `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop is provided with values between `}<inlineCode parentName="p">{`h1-h4`}</inlineCode>{`. It's possible to render other elements with heading styles using `}<inlineCode parentName="p">{`variant`}</inlineCode>{` prop (see `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` for additional explanation). Please reference the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
      }}>{`w3 recommendations for headings`}</a>{` to ensure your headings provide an accessible experience for screen reader users.`}</p>
    <p><strong parentName="p">{`Attention:`}</strong>{` Make sure to include a valid heading element to render a Heading component other than `}<inlineCode parentName="p">{`h1`}</inlineCode>{` (`}<inlineCode parentName="p">{`<Heading as="h2">H2 Element</Heading>`}</inlineCode>{`).`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Heading as="h1">h1 heading</Heading>
<Heading as="h2">h2 heading</Heading>
<Heading as="h3">h3 heading</Heading>
<Heading as="h4">h4 heading</Heading>
<Heading variant="title" as="h1">h1 with title heading styles</Heading>
<Heading variant="h4" as="div">div with h4 heading styles</Heading>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Heading components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` and `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Some notable system props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fontWeight`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'regular', 'bold'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the heading weight, default 'bold'`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Main component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String or React element`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component, 'h1-h4' values also set their corresponding variants`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'title', 'h1', 'h2', 'h3', 'h4'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the heading styles for the component`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      